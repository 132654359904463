
const options = {
    icon: 'info',
    timer: 8000,
    timerProgressBar: true,
    width: 400,
    heightAuto: false,
    padding: '2.5rem',
    buttonsStyling: false,
    confirmButtonColor: null,
    cancelButtonColor: null ,
    confirmButtonText : "Ok, got it!",
    reverseButtons : true ,
    focusConfirm: true  ,
    customClass: {
    confirmButton: "btn mx-1 fw-bold btn-light-primary",
    cancelButton: "btn mx-1 fw-bold btn-light-warning"
    },
}

export const useSwal = () => ({
    
    fire: (obj = {}) => {

        return useNuxtApp().$swal.fire({...options, ...obj })
    },
 
    confirmation: ({
                             confirmButtonText = 'Yes, Do it!',
                             cancelButtonText = 'No, Cancel Operation!',
                             isAsync = true,
                             text = 'Are you sure!?',
                             confirmed_then = () => {
                             },
                             reject_then = () => {
                             },
                             finally_then = () => {
                             },
                             extra_options = {}
                         }) => {

        let confirmation_options = {
            icon: 'question',
            title: 'Confirmation',
            text: text,
            showCancelButton: true,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            timer: false,
            timerProgressBar: false,
        }

        return useSwal().fire({ ...confirmation_options, ...extra_options })
            .then(result => {
                if (result.isConfirmed) {
                    if (isAsync) {
                        confirmed_then(() => finally_then())
                    } else {
                        confirmed_then()
                        finally_then()
                    }
                } else {
                    reject_then()
                    finally_then()
                }
            })
    },

    success: () => useSwal().fire({
        icon: "success",
        title: "Success",
        text: "Operation was successfully done",
    })

})
