export const useApp = () => {

    const config = useRuntimeConfig()

    const appName = config.public.appName
    
    const isFx = () => appName == "fxglory"
    
    const isUp = () => appName == "unitedpips"
    
    return {

        ...config.public,

        isFx, 

        isUp, 

        googleCaptchaSecret: () => {

            if( isFx() )
                return "6LdFKkQkAAAAAM7ypf2nTC_gR_Guy_T9T6qnEOu7"
                
            if( isUp() )
                return "6Leyb-MpAAAAAK8-KOIwKfNRH8RdOWdGzYc6_ZsQ"
        
            return "" 
        }
    }
}